<template>
  <div>
    <section class="blog-details">
        <div class="container">
            <div class="row">
                <!-- Article Area -->
                <div class="col-xl-9">
                    <div class="article-area">
                        <div class="article-title">
                            <h2>Gründung der Dotreflection GmbH</h2>
                        </div>
                        <div class="article-details">
                            <div class="article-author dispmobile">
                                <img src="@/assets/clients/paul_vs.jpg" alt="Habu">
                                <h6><a href="https://paul-goldschmidt.de/">Paul Goldschmidt</a> | <a href="/ueber-uns">Gründer und Geschäftsleitung, Frontendentwicklung</a> | 23. März 2021</h6>
                            </div>
                            <div class="article-author dispdesktop">
                                <img src="@/assets/clients/paul_vs.jpg" alt="Habu">
                                <h6><a href="https://paul-goldschmidt.de/">Paul Goldschmidt</a> | 23. März 2021<br> <a href="/ueber-uns">Gründer und Geschäftsleitung</a></h6>
                            </div>
                        </div>
                        <div class="article-thumbnail">
                            <img src="@/assets/blog/post-details.jpg" alt="Habu">
                        </div>
                        <div class="article-body">
                            <p>Endlich ist es so weit: Nach Monaten der Vorbereitung und Planung, Gesprächen und Terminen wurde die Dotreflection GmbH im März 2021 gegründet.</p>

                            <h2 class="article-subheading">Die Gründung der Firma</h2>

                            <p>Der Gründung der Firma gehen Jahre selbstständiger und freiberuflicher Arbeit voraus. Dabei merkten wir, wie hoch in der Medizinbranche der Bedarf nach einer Agentur ist, die alle Aufgaben der Außendarstellung unter einen Hut bringt.
                              Um dieses Ziel zu erreichen, begannen wir bereits im Sommer 2020 mit den ersten Vorüberlegungen und dem Erstellen von Plänen. Direkt nach dem Jahreswechsel 2020/2021 waren wir dann soweit, die Firma eintragen zu lassen und damit den Wandel von der Selbstständigkeit hin zur Gründung eines Startups zu vollziehen. <br> <br>
                              Nach zwei Monaten Terminen, die eine Firmengründung mit sich bringen und vielen Gesprächen mit Experten aus dem Gesundheitssektor kam am 10. März 2021 eine Nachricht, die aufatmen ließ: Die Firma wurde im Handelsregister gelistet, damit konnten viele Prozesse innerhalb der Firma - von Entwicklung der Webseite bis ersten Gesprächen mit möglichen Kunden - in Gang gesetzt werden.<br>
                              Rund einen Monat später war der Hauptteil der vorbereitenden Arbeit geschafft, und zum 23. April wurde es offziell: Wir, die Dotreflection GmbH, sind als Startup endlich bereit, in den Produktivbetrieb einzusteigen.<br> <br>
                              Und damit an dieser Stelle: <b>Schön, dass Sie den Weg zu uns gefunden haben. Bei Fragen melden Sie sich gerne unter den auf unseren Kontakt-Seiten angegebenen Kanälen. Wir freuen uns auf Sie!</b>
                              </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>


export default {
  name: "PostDetails",
};

</script>

<style scoped>
</style>

<style>

@media screen and (max-width: 991px) {
  .dispmobile {
    display: none !important;
  }
}

@media only screen and (min-width: 991px) {
  .dispdesktop {
    display: none !important;
  }
}
</style>
