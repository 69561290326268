/**
 * @Date:   2021-04-07T14:52:15+02:00
 * @Last modified time: 2021-04-26T20:09:33+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */

<template>
  <div>
    <section class="speciality-area">
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-xl-5 col-lg-6 about-area-image wow animate__animated animate__fadeInLeft">
            <img src="@/assets/banner/about_banner.jpg" alt="Laptop-Bild" />
          </div>
          <div
            class="col-lg-6 speciality-text wow animate__animated animate__fadeInRight"
          >
            <div class="section-heading w-100 mb-28 mt-0">
              <h2>Unsere Arbeit</h2>
              <p>
                Wir beschäftigen uns jeden Tag mit den Herausforderungen der digitalen Transformation im Gesundheitssektor. Dabei arbeiten wir in einem jungen und modernen Umfeld an unseren Projekten.
                <br><br>
                Uns zeichnet aus, dass wir verschiedene Fachbereiche der Informations- und Medientechnik miteinander verbinden. Wir arbeiten mit Erfahrung auf Augenhöhe und machen so unsere Kunden bereit für die Zukunft. Für die Projekte, bei denen vorher viele Unternehmen einzelne Arbeitsfelder abdeckten, bieten wir ein Gesamtpaket  an.
                <br><br>
                Dabei geben wir uns nicht mit dem Status quo zufrieden.<br><b>Wir wollen das erreichen, was andere für unvorstellbar halten. Wir hinterfragen Normen, wagen einen Ausbruch aus der Gewohnheit und setzen einen Schlusspunkt erst dann, wenn jeder zufrieden ist.</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>


export default {
  name: "CompanyDescription",
}
</script>

<style scoped>
</style>
