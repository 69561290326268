<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Datenschutz" pageDescription="Wir sammeln nur ein Minimum an Daten. Welche das sind und wie wir dabei vorgehen, erfahren Sie in dieser Datenschutzerklärung." />
    <PostDatenschutz />
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import PageHeader from '@/components/layout/PageHeader'
import PostDatenschutz from '@/components/page_components/datenschutz/PostDetails.vue'
import Footer from '@/components/layout/Footer'

export default {
  name: "datenschutz",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      PostDatenschutz,
      Footer
  },
  title: 'Datenschutz - Dotreflection GmbH'
};

</script>

<style scoped>
</style>
