<template>
  <div>
    <section class="pricing-area">
        <div class="container">
            <div class="section-heading text-center mx-auto">
                <h2>Freie Stellen bei uns</h2>
                <p>Eine Übersicht über aktuelle Ausschreibungen und freie Stellen.</p>
            </div>

            <div class="row">
                <div class="col-lg-6 wow animate__animated animate__fadeInLeft">
                    <div class="price-box">
                        <JobIntro
                        Title="Webentwickler:in (M/W/D)" />

                        <div class="job-offer">
                            <JobOffer
                            title="Frontendentwicklung"
                            experience="Freiberuflich"
                            location="Heidelberg und Remote" />
                            <JobOffer
                            title="Webentwicklung"
                            experience="Praktikumsstelle"
                            location="Heidelberg, Deutschland" />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 wow animate__animated animate__fadeInRight">
                    <div class="price-box">
                        <JobIntro
                        Title="App-Entwicklung (M/W/D)"/>

                        <div class="job-offer">
                            <JobOffer
                            title="App-Entwickler:in Swift"
                            experience="Freiberuflich"
                            location="Heidelberg, Deutschland" />
                            <JobOffer
                            title="App-Entwickler:in"
                            experience="Freiberuflich"
                            location="Heidelberg, Deutschland" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

import JobIntro from './JobIntro'
import JobOffer from './JobOffer'

export default {
  name: "Jobs",
  components: {
      JobIntro, JobOffer
  }
};

</script>

<style scoped>
</style>
