/**
 * @Date:   2021-04-07T14:52:15+02:00
 * @Last modified time: 2021-04-30T23:04:14+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */



import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/pages/Home.vue'
import About from '@/pages/About.vue'
import Career from '@/pages/Career.vue'
import Contact from '@/pages/Contact.vue'
import Impressum from '@/pages/impressum.vue'
import Datenschutz from '@/pages/datenschutz.vue'
import News from '@/pages/news.vue'
import Mission from '@/pages/mission.vue'
import Not_found from '@/pages/404.vue'
import Easteregg from '@/pages/easteregg.vue'

Vue.use(VueRouter)

const router = new VueRouter ({
    routes : [
        {
            component: Home,
            path: '/',
            name: 'Home'
        },
        {
            component: About,
            path: '/ueber-uns',
            name: 'Das Unternehmen'
        },
        {
            component: Mission,
            path: '/unsere-arbeit',
            name: 'Unsere Arbeit'
        },
        {
            component: Career,
            path: '/karriere',
            name: 'Karriere'
        },
        {
            component: Contact,
            path: '/kontakt',
            name: 'Kontakt'
        },
        {
            component: News,
            path: '/news',
            name: 'News'
        },
        {
            component: Impressum,
            path: '/impressum',
            name: 'Impressum'
        },
        {
            component: Datenschutz,
            path: '/datenschutz',
            name: 'Datenschutz'
        },
        {
            component: Not_found,
            path: '/404',
            name: 'Page not Found'
        },
        {
            component: Easteregg,
            path: '/easteregg',
            name: 'Easteregg'
        },
        {
          path: '*',
          redirect: '/404'
        }
    ],
    mode: "history",
    linkExactActiveClass: "current-menu-item"
})

export default router
