<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Unauffälliges Osterei" pageDescription="Herzlichen Glückwunsch, du hast ein weiteres Osterei auf unserer Seite gefunden!"/>
    <PostImpressum />
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import PageHeader from '@/components/layout/PageHeader'
import PostImpressum from '@/components/page_components/easteregg/egg.vue'
import Footer from '@/components/layout/Footer'

export default {
  name: "impressum",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      PostImpressum,
      Footer
  }
};

</script>

<style scoped>
</style>
