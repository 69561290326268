<template>
  <div>
    <section class="our-team">
        <div class="container">
            <div class="section-heading text-center mx-auto">
                <h2>Unser Team</h2>
                <p>Wir sind ein junges und eingespieltes Team, welches gemeinsam über 20 Jahren Entwicklungs- und Medienerfahrung bietet.</p>
            </div>
            <div class="team-members wow animate__animated animate__fadeInUp">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/paul.jpg" alt="Paul Goldschmidt">
                            <h2>Paul Goldschmidt</h2>
                            <h4>Gründer und Geschäftsleitung, Frontendentwicklung</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="mailto:paul@dotreflection.de"><font-awesome-icon :icon="['fas', 'envelope']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="https://twitter.com/justPaulchen"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="https://www.linkedin.com/in/paulgoldschmidt/"><font-awesome-icon :icon="['fab', 'linkedin']" /></a></div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/torben.jpg" alt="Habu">
                            <h2>Torben Wetter</h2>
                            <h4><br>Webentwicklung (DevOps) und Videografie</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="mailto:torben@dotreflection.de"><font-awesome-icon :icon="['fas', 'envelope']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="https://twitter.com/TorbenWetter"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="https://www.linkedin.com/in/torbenwetter/"><font-awesome-icon :icon="['fab', 'linkedin']" /></a></div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/peter.jpg" alt="Habu">
                            <h2>Peter Heynmöller</h2>
                            <h4><br>Softwareentwicklung und technische Ausstattung</h4>
                            <div class="member-social-profile"><a class="mx-auto" href="mailto:peter@dotreflection.de"><font-awesome-icon :icon="['fas', 'envelope']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="https://twitter.com/PeethaXYZ"><font-awesome-icon :icon="['fab', 'twitter']" /></a></div>
                            <div class="member-social-profile"><a class="mx-auto" href="https://www.linkedin.com/in/peterheynm%C3%B6ller/"><font-awesome-icon :icon="['fab', 'linkedin']" /></a></div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-sm-6">
                        <div class="member-bio">
                            <img src="@/assets/team-members/mitarbeiter.jpg" alt="Habu">
                            <h2>Und vielleicht bald Sie?</h2>
                            <h4>Schauen Sie doch mal auf unserer <router-link to="/karriere">Karriereseite</router-link> vorbei!</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "OurTeam"
};


</script>

<style scoped>
</style>
