<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import "animate.css";
import './stylesheets/default.css'
import './stylesheets/style.css'
import './stylesheets/responsive.css'
import 'normalize.css'

export default {
  name: "App",
};
</script>
