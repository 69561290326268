/**
 * @Date:   2021-04-07T14:52:15+02:00
 * @Last modified time: 2021-04-20T21:25:12+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */



<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <BannerStyle1 />
    <FeaturesStyle1 />
    <Speciality />
    <Footer />
  </div>
</template>

<script>
/* Oben einfügbar:
<CompaniesSlider />
<PostsHome />
<Services3Column />
<ProjectsHome />
<Reviews />
*/
import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import BannerStyle1 from '@/components/page_components/home/home_1/BannerStyle1'
import FeaturesStyle1 from '@/components/page_components/home/home_1/FeaturesStyle1'
/*import CompaniesSlider from '@/components/common/companiesSlider'*/
/*import Services3Column from '@/components/page_components/home/home_1/Services3Column'*/
/*import ProjectsHome from '@/components/page_components/home/ProjectsHome'*/
import Speciality from '@/components/page_components/home/Speciality'
/*import PostsHome from '@/components/page_components/home/PostsHome'*/
/*import Reviews from '@/components/common/Reviews'*/
import Footer from '@/components/layout/Footer'

export default {
  name: "Home1",
  components: {
      TopHeader,
      MenuStyle1,
      BannerStyle1,
      FeaturesStyle1,
      /*CompaniesSlider,*/
      /*Services3Column,*/
      /*ProjectsHome,*/
      Speciality,
      /*PostsHome,*/
      /*Reviews,*/
      Footer
  },
  title: 'Home - Dotreflection GmbH'
};

</script>
<style scoped>
</style>
