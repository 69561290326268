<template>
  <div>
    <section class="blog-details">
        <div class="container">
            <div class="row">
                <!-- Article Area -->
                <div class="col-xl-9">
                  <h2 class="article-subheading bottom-space-small">
                  Wir haben zwar nicht die Seite, die Sie suchen, dafür aber einen Bild eines Hamsters: Das ist Frederick
                  </h2>
                    <div class="article-area">
                      <div class="article-thumbnail bottom-space-small">
                        <img src="@/assets/banner/404.jpg" alt="Hamster-Bild" />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>


export default {
  name: "PostImpressum",
};

</script>

<style scoped>
</style>

<style>
  .bottom-space-small {
     margin-bottom: 1.5cm;
  }
</style>
