<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Fehler 404" pageDescription="Tut uns leid, diese Seite konnte nicht gefunden werden."/>
    <PostImpressum />
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import PageHeader from '@/components/layout/PageHeader'
import PostImpressum from '@/components/page_components/404/error.vue'
import Footer from '@/components/layout/Footer'

export default {
  name: "impressum",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      PostImpressum,
      Footer
  },
  title: 'Fehler 404: Seite nicht gefunden'
};

</script>

<style scoped>
</style>
