<template>
  <div :class="{menu_header_dark: scrollPosition >= 550}" class="menu-header d-flex align-items-center">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-4 d-flex align-items-center">
          <router-link to="/">
          <img :src="isDefaultImage ? require('@/assets/logo/site-logo/logo_fullres.png') : require('@/assets/logo/site-logo/logo_white_fullres.png')" width="300" height="60" alt="Logo" class="responsive"/>
          </router-link>
        </div>

        <div class="col-9 d-flex align-items-center">
          <router-link to="/">
          <img :src="isDefaultImage ? require('@/assets/logo/site-logo/logo_fullres.png') : require('@/assets/logo/site-logo/logo_white_fullres.png')" width="300" height="60" alt="Logo" class="responsive"/>
          </router-link>
        </div>

        <div class="col-8 d-flex align-items-center">
          <nav class="menu-area d-lg-flex align-items-center ml-auto">
            <ul>
              <router-link to="/ueber-uns" tag="li">
                <a href="javascript:void(0)">Das Unternehmen</a></router-link
              >
              <router-link to="/unsere-arbeit" tag="li">
                  <a href="javascript:void(0)">Unsere Arbeit</a></router-link
                >
              <router-link to="/news" tag="li"
                  ><a href="javascript:void(0)">Neuigkeiten</a></router-link
                >
              <router-link to="/karriere" tag="li"
                ><a href="javascript:void(0)">Karriere</a></router-link
              >
            </ul>
          </nav>
          <router-link to="/kontakt" class="large-blue-button menu-button">Kontakt</router-link>
        </div>

        <div class="col-2 d-flex align-items-center">
          <div class="mobile-menu-button ml-auto">
            <button @click="mobileMenu = !mobileMenu">
              <font-awesome-icon :icon="['fas', 'bars']" />
            </button>
          </div>
        </div>
      </div>

      <transition enter-active-class="animate__animated animate__fadeInRight" leave-active-class="animate__animated animate__fadeOutRight" >
        <div class="mobile-menu-area" v-show="mobileMenu">
          <particles />
          <div class="mobile-menu-components">
            <div class="row justify-content-between mobile-menu-header">
              <div class="col-6 d-flex align-items-center">
                <h1>Menü</h1>
              </div>
              <div class="col-6 d-flex align-items-center">
                <button @click="mobileMenu = false" class="ml-auto">
                  <font-awesome-icon :icon="['fas', 'times']" />
                </button>
              </div>
            </div>

            <nav class="mobile-menu mx-auto">
              <ul>
              <ul>
                <router-link to="/" tag="li">
                  <a href="javascript:void(0)">Home</a></router-link
                >
                <router-link to="/ueber-uns" tag="li">
                  <a href="javascript:void(0)">Das Unternehmen</a></router-link
                >
                <router-link to="/unsere-arbeit" tag="li">
                    <a href="javascript:void(0)">Unsere Arbeit</a></router-link
                  >
                <router-link to="/news" tag="li"
                    ><a href="javascript:void(0)">Neuigkeiten</a></router-link
                  >
                <router-link to="/karriere" tag="li"
                  ><a href="javascript:void(0)">Karriere</a></router-link
                >
              </ul>
              </ul>
            </nav>
            <p>Copyright © {{new Date().getFullYear()}} Dotreflection GmbH. Alle Rechte vorbehalten.</p>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import particles from "@/components/common/particles.vue";
export default {
  name: "MenuStyle1",
  components: {
    particles,
  },
  data: function () {
    return {
      scrollPosition: null,
      isDefaultImage: true,
      mobileMenu: false,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;

      if (window.scrollY > 550) {
        return (this.isDefaultImage = false);
      }
      if (window.scrollY <= 550) {
        if (!this.defaultImage) {
          return (this.isDefaultImage = true);
        }
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style>
.mobile-menu-button button {
  color: rgb(51, 120, 30);
  border: none;
  background: transparent;
  font-size: 25px;
  padding: 0px 15px;
}
.menu_header_dark .mobile-menu-button button {
  color: #fff;
}
.mobile-menu-button {
  display: none;
}

.mobile-menu-area {
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  color: #647589;
  width: 100%;
  height: min-content;
  height: -webkit-min-content;
  height: -moz-min-content;
  z-index: 1000;
}
.mobile-menu-area button {
  background: transparent;
  border: none;
  color: #fff;
  font-size: 30px;
}
nav.mobile-menu {
  width: 100%;
  overflow-y: scroll;
}
nav.mobile-menu ul {
  list-style: none;
  padding-left: 17px;
}
nav.mobile-menu a {
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  color: #b5b5b5;
  line-height: 2.05em;
  padding: 5px 0;
}
nav.mobile-menu ul li.current-menu-item > a,
nav.mobile-menu ul li a:hover {
  color: #fff;
}
.mobile-menu-components {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.mobile-menu-components p {
  padding: 0 15px;
}

.mobile-menu-header {
  height: 90px;
}
.mobile-menu-header h1 {
  color: #fff;
  font-size: 27px;
  margin: 0;
}

.responsive {
  width: 100%;
  height: auto;
}


@media screen and (max-width: 1050px) {
  .mobile-menu-button {
    display: inherit;
  }
  .col-4 {
    display: none !important;
  }
  .col-8 {
    display: none !important;
  }
}

@media only screen and (min-width: 1050px) {
  .col-9 {
    display: none !important;
  }
}
</style>
