<template>
  <div>
    <div class="page-header">
        <div class="container">
            <div class="page-header-text wow animate__animated animate__fadeInDown">
                <h1 class="page-title">{{pageTitle}}</h1>
                <span class="font-weight-bold">{{pageDescription}}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "PageHeader",
  props: ['pageTitle', 'pageDescription']
};
</script>

<style scoped>
</style>
