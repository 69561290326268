<template>
  <div>
    <section class="features-area" id="features-area">
        <div class="container">
            <div class="section-heading">
                <h1>Unsere Leistungen</h1>
                <p>Mit mehr als sechs Jahren Erfahrung sind wir starke Partner für jedes Projekt.</p>
            </div>
            <div class="row">
                <div class="col-md-4 col-sm-4 wow animate__animated animate__fadeInLeft">
                    <div class="feature-box">
                        <div class="f-point">
                            <h1>01</h1>
                        </div>
                        <div class="feature">
                            <div class="f-description">
                                <h1>Web- entwicklung und Design</h1>
                                <p>Wir entwickeln anpassungsfähige Webseiten mit modernsten Technologien, die den Zeitgeist treffen.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 wow animate__animated animate__fadeInUp">
                    <div class="feature-box">
                        <div class="f-point">
                            <h1>02</h1>
                        </div>
                        <div class="feature">
                            <div class="f-description">
                                <h1>Mediale Darstellung Ihrer Projekte</h1>
                                <p>Seien es Imagevideos oder das Begleiten von Messen - wir haben das Know-how, um Sie und Ihre Mission der Öffentlichkeit bekannt zu machen.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4 wow animate__animated animate__fadeInRight">
                    <div class="feature-box">
                        <div class="f-point">
                            <h1>03</h1>
                        </div>
                        <div class="feature">
                            <div class="f-description">
                                <h1>Beratungen und Fachvorträge</h1>
                                <p>Sie wollen in dem Bereich der digitalen Medien aktiver werden? Wir helfen gerne mit unserer Erfahrung dabei, Ihre Mission umzusetzen.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>

export default {
  name: "FeaturesStyle1",
};
</script>

<style scoped>
</style>
