/**
 * @Date:   2021-04-07T14:52:15+02:00
 * @Last modified time: 2021-05-01T11:33:42+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */



import Vue from 'vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import VueTypedJs from 'vue-typed-js'
import App from './App.vue'
import router from './router'
import titleMixin from './mixins/titleMixin'

import VueMatomo from 'vue-matomo';

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

import FlashMessage from '@smartweb/vue-flash-message';
Vue.use(FlashMessage);
Vue.mixin(titleMixin)

import {faPhoneAlt, faEnvelope, faStar, faSearch, faChevronUp,
  faLongArrowAltRight, faLongArrowAltLeft, faPlayCircle, faArrowAltCircleLeft, faArrowAltCircleRight,
  faWindowClose, faBars, faTimes}
  from '@fortawesome/free-solid-svg-icons'

import {faFacebook, faFacebookF, faTwitter, faYoutube,
  faInstagram, faSnapchat, faBehance, faLinkedin}
  from '@fortawesome/free-brands-svg-icons'

library.add(faPhoneAlt, faEnvelope, faFacebook, faFacebookF,
  faTwitter, faYoutube, faInstagram, faSnapchat, faStar, faSearch,
  faChevronUp, faLongArrowAltRight, faLongArrowAltLeft, faPlayCircle, faBehance,
  faArrowAltCircleLeft, faArrowAltCircleRight, faWindowClose, faBars, faTimes, faLinkedin)

  Vue.use(VueMatomo, {
    host: "https://analytics.dotreflection.de",
    siteId: 1,
    trackerFileName: 'matomo',
    router: router,
    enableLinkTracking: true,
    requireConsent: false,
    trackInitialView: true,
    disableCookies: false,
    enableHeartBeatTimer: false,
    heartBeatTimerInterval: 15,
    debug: false,
    userId: undefined,
    cookieDomain: undefined,
    domains: undefined,
    preInitActions: []
  });

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueTypedJs)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
