<template>
  <div>
    <section class="contact-area">
        <div class="container">
            <div class="section-heading text-center mx-auto wow animate__animated animate__slideInUp">
                <h2>Unser Büro in Heidelberg</h2>
                <p>Unser Büro in Heidelberg ist der Dreh- und Angelpunkt der Dotreflection GmbH: Hier entstehen schon heute die Webauftritte von morgen. Wenn die Lage es zulässt, können Sie gerne nach vorheriger Absprache auch persönlich vorbei kommen.<br>Wir freuen uns auf Sie!</p>
            </div>
            <!-- Location -->
            <div class="location-map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2595.0583861157806!2d8.643267116431776!3d49.42671017934748!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797c7338b7a7d69%3A0xea4f8659d97547f2!2sDotreflection%20GmbH!5e0!3m2!1sde!2sde!4v1619290389969!5m2!1sde!2sde"
                     height="601" style="border:0; width: 100%;" allowfullscreen="" aria-hidden="false"
                    tabindex="0"></iframe>
            </div>
            <!-- Address -->
            <div class="address-container wow animate__animated animate__fadeIn">
                <div class="row">
                    <div class="col-sm-4 col-6 d-flex align-items-center">
                        <div class="address-box mx-auto">
                            <img class="mx-auto" src="@/assets/svgs/map.png" alt="map">
                            <p>Grenzhöfer Weg 12<br>69123 Heidelberg<br>Deutschland</p>
                        </div>
                    </div>
                    <div class="col-sm-4 col-6 d-flex align-items-center">
                        <div class="address-box mx-auto">
                            <img class="mx-auto" src="@/assets/svgs/chatting.png" alt="map">
                            <a href="tel:062215999720">+49 (0) 6221 5999720</a><br><br>
                        </div>
                    </div>
                    <div class="col-sm-4 col-6 mx-auto d-flex align-items-center">
                        <div class="address-box mx-auto">
                            <img class="mx-auto" src="@/assets/svgs/message.png" alt="map">
                            <a href="mailto:kontakt@dotreflection.de">kontakt@dotreflection.de</a>
                            <a href="mailto:presse@dotreflection.de">presse@dotreflection.de</a>
                        </div>
                    </div>
                </div>
            </div>

            <ContactForm />
        </div>
    </section>
  </div>
</template>

<script>

import ContactForm from './ContactForm'

export default {
  name: "AddressBox",
  components: {
      ContactForm
  }
};

</script>

<style>
section.contact-area {
    background-image: url('~@/assets/svgs/map-bg.png');
}
</style>
