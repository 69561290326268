<template>
  <div>
    <div class="top-header d-flex align-items-center">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-xl-5 col-lg-5 col-md-6 col-12">
                    <div class="top-header-contact phonenumber">
                        <a href="tel:062215999720">
                            <font-awesome-icon :icon="['fas', 'phone-alt']" />+49 (0) 6221 5999720
                        </a>
                    </div>
                    <div class="top-header-contact">
                        <a href="mailto:kontakt@dotreflection.de">
                            <font-awesome-icon :icon="['fas', 'envelope']" />kontakt@dotreflection.de
                        </a>
                    </div>
                </div>
                <div class="col-xl-2 col-lg-3 col-md-4 col-sm-0 text-right top-header-social">
                    <a href="https://www.facebook.com/dotreflection">
                        <font-awesome-icon :icon="['fab', 'facebook-f']" />
                    </a>
                    <a href="https://twitter.com/dotreflection">
                        <font-awesome-icon :icon="['fab', 'twitter']" />
                    </a>
                    <a href="https://www.instagram.com/dotreflection">
                        <font-awesome-icon :icon="['fab', 'instagram']" />
                    </a>
                    <a href="https://www.youtube.com/channel/UChiVcT6vVnJWL0svVdSOuzA">
                        <font-awesome-icon :icon="['fab', 'youtube']" />
                    </a>
                    <a href="https://www.linkedin.com/company/dotreflection/">
                        <font-awesome-icon :icon="['fab', 'linkedin']" class="m-0" />
                    </a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopHeader",
};
</script>

<style scoped>
</style>

<style>
  @media screen and (max-width: 991px) {
    .top-header {
      display: none !important;
    }
  }
</style>
