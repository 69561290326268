/**
 * @Date:   2021-04-24T15:51:09+02:00
 * @Last modified time: 2021-04-26T17:13:30+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */



<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="News" pageDescription="Hier erfahren Sie, was sich bei uns verändert. Und über das, was wir verändern."/>
    <PostDetails />
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import PageHeader from '@/components/layout/PageHeader'
import PostDetails from '@/components/page_components/news/PostDetails.vue'
import Footer from '@/components/layout/Footer'

export default {
  name: "news",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      PostDetails,
      Footer
  },
  title: 'News - Dotreflection GmbH'
};

</script>

<style scoped>
</style>
