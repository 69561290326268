<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Karriere" pageDescription="Schauen Sie gerne in unsere aktuellen Stellenangebote rein! Nichts dabei? Schreiben Sie uns doch gerne eine Mail mit einer Initiativbewerbung, wir sind immer offen für kreative Köpfe!" />
    <Jobs />
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import PageHeader from '@/components/layout/PageHeader'
import Jobs from '@/components/page_components/career/Jobs'
import Footer from '@/components/layout/Footer'

export default {
  name: "Career",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      Jobs,
      Footer
  },
  title: 'Karriere - Dotreflection GmbH'
};

</script>

<style scoped>
</style>
