/**
 * @Date:   2021-04-07T14:52:15+02:00
 * @Last modified time: 2021-04-27T20:10:56+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */



<template>
  <div>
    <section class="posts-area">
        <div class="container">
            <div class="section-heading text-center mx-auto">
                <h1>Unsere Grundsätze als Unternehmen</h1>
                <p>Wir bauen auf sechs grundlegenden Überzeugungen und Leitideen all unsere Projekte auf.</p>
            </div>
            <div class="row">
                <Post
                :category="post1.category"
                :postThumbnail="post1.postThumbnail"
                :title="post1.title"
                :Excerpt="post1.excerpt"/>

                <Post
                :category="post2.category"
                :postThumbnail="post2.postThumbnail"
                :title="post2.title"
                :Excerpt="post2.excerpt"/>

                <Post
                :category="post3.category"
                :postThumbnail="post3.postThumbnail"
                :title="post3.title"
                :Excerpt="post3.excerpt"/>

                <Post
                :category="post4.category"
                :postThumbnail="post4.postThumbnail"
                :title="post4.title"
                :Excerpt="post4.excerpt"/>

                <Post
                :category="post5.category"
                :postThumbnail="post5.postThumbnail"
                :title="post5.title"
                :Excerpt="post5.excerpt"/>

                <Post
                :category="post6.category"
                :postThumbnail="post6.postThumbnail"
                :title="post6.title"
                :Excerpt="post6.excerpt"/>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import Post from '@/components/page_components/blog/PostExcerpt'

export default {
  name: "PostsHome",
  components: {
      Post
  },
  data(){
      return {
        post1: {
            category: "IT-Sicherheit",
            postThumbnail: require('@/assets/blog/post-01.jpg'),
            title: "IT-Sicherheit seit der ersten Stunde",
            excerpt: "Unsere IT-Infrastruktur ist mit höchsten Sicherheitsstandards entworfen und ausgearbeitet: Zero Trust Network Access (ZTNA) und der Einsatz modernster krypografischer Methoden in unseren Projekten helfen neben unabhängigen Tests (Pentests) von extern beauftragten Firmen dabei, die Sicherheit und Integrität unserer Netzwerkinfrastruktur in allen Lagen zu gewährleisten."
        },
        post2: {
            category: "Datenschutz",
            postThumbnail: require('@/assets/blog/post-02.jpg'),
            title: "Die DSGVO weitergedacht",
            excerpt: "Wir erachten den sorgsamen Umgang mit Daten als grundlegend in der digitalen Welt. Deshalb kooperieren wir im Bereich des Datenschutzes mit führenden Rechtskanzleien und sind darauf bedacht, nur da, wo es tatsächlich einen Vorteil bringt, Daten in den von uns entwickelten Lösungen zu sammeln. So können beispielsweise Seitenstatistiken und die Auswertung von Nutzungszahlen gut umgesetzt werden, ohne das Vertrauen der Nutzer zu verspielen."
        },
        post3: {
            category: "Transparenz",
            postThumbnail: require('@/assets/blog/post-03.jpg'),
            title: "Wir zeigen, was wir machen",
            excerpt: "Uns ist es wichtig, dass alle Beteiligten an einem Projekt wissen, was sowohl auf technischer wie auch auf inhaltlicher Ebene passiert. In der Planungsphase eines Projektes stellen wir die Vor- und Nachteile bestimmter Vorgehensweisen bei einem Projekt vor. Dazu bekommen unsere Kunden nach dem Gestalltungsprozess eine Übersicht, in dem die Spezifikationen des Projektes und eine Aufstellung der geleisteten Arbeit gut verständlich aufgeschlüsselt werden."
        },
        post4: {
            category: "Nachhaltigkeit",
            postThumbnail: require('@/assets/blog/post-04.jpg'),
            title: "Klima- und Umweltschutz ist mehr als ein grüner Mantel",
            excerpt: "Wir sind stolz darauf, CO₂-neutral zu sein: Wann immer es geht, verzichten wir auf die Nutzung von Auto und Flugzeug. Unser komplettes Servernetzwerk wird mit 100% Ökostrom betrieben - bei der Arbeit mit externen Dienstleistern werben wir für CO₂-neutralen Geschäftsbetrieb . Das CO₂, dass wir unvermeidlich ausstoßen, kompensieren wir durch den Kauf von entsprechenden Ausgleichszertifikaten."
        },
        post5: {
            category: "Soziale Verantwortung",
            postThumbnail: require('@/assets/blog/post-05.jpg'),
            title: "Verantwortungsbewusst gegenüber allen Beteiligten",
            excerpt: "Unter sozialer Verantwortung verstehen wir die Handlungsfelder des Leitfadens DIN ISO 2600: Faire Betriebs- und Geschäftspraktiken sowie Einbindung und Entwicklung der Gesellschaft (z.B. durch öffentliche Vorträge in unserem Unternehmen) sind zwei der sieben Handlungsfelder des Leitfadens, denen wir uns verpflichtet fühlen."
        },
        post6: {
            category: "Betriebliches Kontinuitätsmanagement",
            postThumbnail: require('@/assets/blog/post-06.jpg'),
            title: "Auf alle Sitationen vorbereitet",
            excerpt: "Es ist immer möglich, dass eine oder mehrere Komponenten in der IT-Infrastruktur ausfallen. Sei es ein längerfristiger Netzwerkausfall oder ein Großbrand - wir haben einen Überblick, was bei unterschiedlichen Situationen getan werden muss. So haben wir einen Plan zur Katastrophenwiederherstellung (Disaster Recovery Plan) erarbeitet, der einen fortlaufenden Betrieb auch unter besonderen Umständen erlaubt."
        }
      }
  }
};
</script>

<style scoped>
</style>
