/**
 * @Date:   2021-04-07T14:52:15+02:00
 * @Last modified time: 2021-04-25T10:20:22+02:00
 * @Copyright: Copyright 2020, Heidelberg (Germany)
 */
 <template>
  <!-- Contact Form -->
            <div class="contact-form-area wow animate__animated animate__fadeIn">
                <h2>Senden Sie uns eine Nachricht - wir melden uns schnellstmöglich bei Ihnen</h2>
                <div class="row">
                    <form @submit.prevent="sendEmail">
                        <div class="col-md-4">
                        <input
                          type="text"
                          v-model="name"
                          name="name"
                          placeholder="Ihr Name"
                        >
                        </div>
                        <div class="col-md-4">
                        <input
                          type="email"
                          v-model="email"
                          name="email"
                          placeholder="Ihre E-Mail-Adresse"
                          >
                        </div>
                        <div class="col-md-4">
                        <input
                          type="text"
                          v-model="subject"
                          name="subject"
                          placeholder="Betreff"
                          >
                        </div>
                        <div class="col-12">
                        <textarea
                          name="message"
                          v-model="message"
                          cols="30" rows="5"
                          placeholder="Nachricht">
                        </textarea>
                            <input class="large-blue-button" type="submit" value="Nachricht Senden">
                        </div>
                    </form>
                     <FlashMessage></FlashMessage>
                </div>
            </div>
</template>

 <script>
 import emailjs from 'emailjs-com';

 export default {
   name: 'ContactUs',
   data() {
     return {
       name: '',
       email: '',
       message: '',
       subject: ''
     }
   },
   methods: {
     sendEmail(e) {

       try {
         emailjs.sendForm('service_k70unwc', 'template_s6x5lbj', e.target, 'user_8dZl0VGJBIu8fsd8itfRV')
         this.flashMessage.success({
         title: 'Nachricht erfolgreich versendet!',
         message: 'Vielen Dank für Ihre Nachricht, wir melden uns so schnell wie möglich bei Ihnen.'
         });

       } catch (error) {
           console.log({error})
           this.flashMessage.error({
             title: 'Fehler beim Versenden der Nachricht',
             message: 'Leider konnte Ihre Nachricht nicht versendet werden, bitte versuchen Sie es telefonisch oder per Mail.'
           });
       }
       // Reset form field
       this.name = ''
       this.email = ''
       this.subject = ''
       this.message = ''
     },
   }
 }
 </script>
<style scoped>
</style>
