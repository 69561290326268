<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Über uns" pageDescription="Wir arbeiten daran, den Medizinsektor im 21. Jahrhundert medial darzustellen." />
    <CompanyDescription />
    <Experience />
    <OurTeam />
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import PageHeader from '@/components/layout/PageHeader'
import CompanyDescription from '@/components/page_components/about_components/CompanyDescription'
import Footer from '@/components/layout/Footer'
import OurTeam from '@/components/page_components/about_components/OurTeam'
import Experience from '@/components/page_components/about_components/Experience'

export default {
  name: "About",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      CompanyDescription,
      OurTeam,
      Experience,
      Footer
  },
  title: 'Über uns - Dotreflection GmbH'
};

</script>

<style scoped>
</style>
