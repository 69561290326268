<template>
  <div>
    <section class="blog-details">
        <div class="container">
            <div class="row">
                <!-- Article Area -->
                <div class="col-xl-9">
                    <div class="article-area">
                      <div class="article-thumbnail bottom-space-small">
                        <img src="@/assets/banner/celebration.jpg" alt="Feiernde Hunde" />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </div>
</template>

<script>
import Vue from 'vue'
import VueConfetti from 'vue-confetti'
Vue.use(VueConfetti)

export default {
  name: "PostImpressum",
  methods: {
      start() {
        this.$confetti.start();
      },
  },
  beforeMount(){
    this.start()
  }
};

</script>

<style scoped>
</style>

<style>
  .bottom-space-small {
     margin-bottom: 1.5cm;
  }
</style>
