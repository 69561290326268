<template>
  <div>
    <section class="footer-area">
        <div class="container position-relative">
        <cookie-law theme="base" buttonText='Verstanden!'>
          <div slot="message">
          Diese Seite setzt Cookies ein, damit die korrekten Darstellung und Funktionalität der Seite gewährleistet werden kann. Mehr Informationen zu unserem Umgang mit Daten finden Sie in der <router-link to="datenschutz">Datenschutzerklärung</router-link>.
          </div>
        </cookie-law>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-10">
                    <h2>Kontakt</h2>
                    <p><a href="tel:062215999720">+49 (0) 6221 5999720</a><br><a href="mailto:kontakt@dotreflection.de">kontakt@dotreflection.de</a><br>Grenzhöfer Weg 12<br>69123 Heidelberg<br>Mon-Fr 08:00 Uhr - 17:30 Uhr</p>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-10">
                    <h2>Die Dotreflection GmbH</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/unsere-arbeit">Verantwortung</a></li>
                        <li><a href="/impressum">Impressum</a></li>
                        <li><a href="/datenschutz">Datenschutz</a></li>
                    </ul>
                    <a href="/"><img src="@/assets/logo/site-logo/logo_fullres.png" width="300" height="60" class="responsive"></a>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-9 col-12">
                    <h2>Wir in zwei Sätzen</h2>
                    <p>Wir sind ein Startup aus Heidelberg mit dem Ziel, den Gesundheitssekor im Bereich der digitalen Medien weiter zu bringen. Dabei fühlen wir uns unseren Firmenwerten verpflichtet, die uns seit der Gründung begleiten.</p>
                </div>
                <a class="go-to-top" href="#"><font-awesome-icon :icon="['fas', 'chevron-up']" /></a>
            </div>
        </div>
    </section>
    <div class="container text-center copyright">
        <p>Copyright © {{new Date().getFullYear()}} Dotreflection GmbH. Alle Rechte vorbeha<a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" rel="nofollow" target="_blank">l</a>ten.</p>
    </div>
  </div>
</template>

<script>

  import CookieLaw from 'vue-cookie-law'

  console.log("Hallo, ❤️-lich Willkommen auf unserer Webseite! Als Person, die die Entwicklerkonsole kennt, begrüßen wir dich hier in unserem Debugging-Zuhause. Um die wahrscheinlich dringenste Frage direkt zu klären: diese Seite wurde in Vue.js (mit Vue Router) im April 2021 entwickelt.");
  console.log("Wir haben auf dieser Webseite insgesamt vier Easter-Eggs verteilt (das hier ist eins der vier Stück), wenn du alle findest, schicke eine Mail mit den Orten der Easter-Eggs an kontakt@dotreflection.de! Solange der Vorrat reicht haben wir noch T-Shirts und Hoodies da, die wir dir gerne dann zukommen lassen würden - natürlich gratis. (Achtung: Easter-Eggs, die auch ohne Angriff von uns und unseres Servernetzwerkes findbar sind und definitiv auf dieser Webseite liegen. Dies ist explizit keine Einladung und keine Erlaubnis, unsere Server zu manipulieren, zu attackieren oder sonstige Angriffe ohne unsere schriftliche Einverständniserklärung zu starten oder durchzuführen.)");
  console.log("Wenn du grade auf der Suche nach einem Job bist, schau' doch mal auf unserer Karriereseite vorbei - so technisch kompetente Mitarbeiter:innen sucht man lange. 👨‍💻 👩‍💻");

  export default {
    components: { CookieLaw },
    name: "Footer",
  };
</script>
<style scoped>
</style>

<style>
  .responsive {
    max-width: 80%;
    height: auto;
  }
</style>
