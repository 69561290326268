<template>
  <div>
    <TopHeader />
    <MenuStyle1 />
    <PageHeader pageTitle="Unsere Tätigkeiten und Grundsätze" pageDescription="Die Leistungen und Versprechen, auf denen wir unser Unternehmen aufbauen" />
    <services/>
    <goals/>
    <Footer />
  </div>
</template>

<script>

import TopHeader from '@/components/layout/TopHeader'
import MenuStyle1 from '@/components/layout/MenuStyle1'
import services from '@/components/page_components/home/home_1/Services3Column.vue'
import goals from '@/components/page_components/home/PostsHome.vue'
import PageHeader from '@/components/layout/PageHeader'
import Footer from '@/components/layout/Footer'

export default {
  name: "About",
  components: {
      TopHeader,
      MenuStyle1,
      PageHeader,
      goals,
      services,
      Footer
  },
  title: 'Unsere Arbeit - Dotreflection GmbH'
};

</script>

<style scoped>
</style>
