<template>
  <div class="speciality">
    <div class="job-text">
      <div class="sp-svg">
        <svg
          data-name="Why Us"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          width="24"
          height="25"
          viewBox="0 0 24 25"
        >
          <g>
            <image
              width="24"
              height="25"
              xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAClUlEQVRIia3WT4iVVRQA8N/3Rp0SqTBcCCVCfxYtxpUxRBGJk40KRZZhRAuhja6kYKLojxHlPiRbRIHNqEEUgZk2Lvo72q5cSoSpMUSBOx149/vivs4Xz+f35k2T53G4555zz/9z7/cKZwyC5XgA67AGl/AmrgzUROHnvrK7sRu7sKRHthqzC3GwRNnI34tXu/af4Vv8hl8XarzJQYGTeCj2e/BhlOW/wnbc2uvgB4ziOzyF3xdhOMOTOPJPBulf5kdhfBpjizRcG/846PHCTIfYEKWZjQYuFnZgKnS34midwfvB3PY/I7/KuCjRg1iL09GDxcD2uubYjGO1jeygjvqd61CWR3C8W9iSbOqUKTkZa8bbJDOSF7t4Tfi0ZCr4WyXHe8/kDPKNneu5PMMxURnP4VBD5Lksk01l6bKxrhWe/ujx/Ivk/qBzhJt75DnyI0HnChxryG5CcrrVuWilm2Ltxu+VtsT+qNL6oJ9Rmgx6TOlEg27GNXltRfQ3S1Y0RPGFZGfQX0lekxyM/bhkep7+3JLXVldzR/sc/EDyfATxevDGJF8OGIBOiYc8YVhlm8pSlU9UNOCMygqVu1QeU5nuc67GEZUXVM4UDrkxXstlWIU/55n5oYhvEJyI9+zxlrbL2ia00XY41n6YBsgzjmsb0zar7dMhj3ZSOqWyQ2W9qvP7ekAJ+uGdYatQeVjlQqtrrDYqzSntVXqjz+jNh6NKPykVSi8pzdRjWnf9guReyZzklZiukQGTkvEGycvxtCyX7JO8XcsL713TrttxGPfFPn+A8kv5Iy5GpVfinnginsPSOPssDnYbK7zbdx6y4gTuWMDUHMBbON8rKOwfqJy/dpswEtnlvzB5lM/iG3yOvxo18TcLSmY7D+WjfQAAAABJRU5ErkJggg=="
            ></image>
          </g>
        </svg>
      </div>
      <div class="sp-job-details">
        <p>
          {{title}} <br />
          {{experience}} | {{location}}
        </p>
      </div>
      <div class="job-apply ml-auto">
        <a href="/kontakt">Bewerben</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JobOffer",
  props: ['title', 'experience', 'location'],
};
</script>

<style scoped>
</style>
